import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react';
// import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from "./Data/Features/authSlice";
import userReducer from "./Data/Features/userSlice";
import sessionMiddleware from "./Data/middleware/sessionMiddleware";
import { userAuthApi } from "./Data/services/userAuthApi";

import defaultReducer from "./Data/Features/DefaultReducer";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    // RTK Query API
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    // Local storage Redux
    user: userReducer,
    authInfo: authReducer,
    default: defaultReducer,
  },
  // load from local storage

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userAuthApi.middleware,
      sessionMiddleware, // for session check each api response
    ]),
});
